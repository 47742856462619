import { CalendarOutlined, MessageOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Row } from 'antd';
import ConfirmModal from 'components/Modals/ConfirmModals/index';
import { strings } from 'common/index';
import { useRedux } from 'src/hooks';
import { Link } from 'react-router-dom';
import InprogressButtons from 'ui-kits/Buttons/InprogressButtons';
import './styles.css';
import { URL } from 'src/api/api';

const CardItemView = (props: any) => {
  const {
    editedTime,
    name,
    status,
    id,
    editProject,
    showArchiveModal,
    setArchiveModalVisible,
    archiveModalVisible,
    archiveProject,
    isArchived,
    projectListType,
    activateProject,
    users,
    messages_length,
  } = props;

  const {
    rooms_completed,
    goods_completed,
    information_completed,
    inspirations_completed,
  } = props?.projectItem;

  let role = localStorage.getItem('userRole');
  const { Save } = useRedux();

  const switchStatus = (isDone: any, text: string) => (
    <Row
      className={`status_row_container ${isDone ? 'done' : 'notDone'}`}
      justify="space-between"
    >
      <Col
        span={18}
        className={`text `}
      >
        {text}
      </Col>
      <Col
        span={6}
        className={`status ${isDone ? 'done' : 'notDone'}`}
      >
        {isDone ? 'Done' : 'Not done'}
      </Col>
    </Row>
  );
  return (
    <>
      {((isArchived === false && projectListType === 'activate') ||
        (isArchived && projectListType === 'archive')) && (
        <>
          <Col
            xl={8}
            xs={24}
            key={id}
          >
            <div
              className="card-container"
              key={id}
            >
              <Card
                bordered={false}
                key={id}
              >
                <Row justify="space-between">
                  <Col className="status-div">
                    <Link
                      onClick={() => editProject()}
                      to={`/add-project/${id}`}
                    >
                      {name}
                    </Link>
                    <span className="date_container">
                      <CalendarOutlined />
                      {editedTime}
                    </span>
                  </Col>
                  <Col className="status_btn">
                    <InprogressButtons status={status} />
                  </Col>
                </Row>
                <div>
                  <ul
                    style={{ width: '100%' }}
                    className="status_container"
                  >
                    <Link
                      onClick={() => Save({ activeTab: 'PROJECTDATA' }, 'app')}
                      to={`/add-project/${id}`}
                    >
                      <li>
                        {switchStatus(
                          information_completed,
                          'Step 1: Tell us about you'
                        )}
                      </li>
                    </Link>
                    <Link
                      onClick={() => Save({ activeTab: 'PROJECTDATA' }, 'app')}
                      to={`/add-project/${id}`}
                    >
                      <li>
                        {switchStatus(
                          inspirations_completed,
                          'Step 2: Collect your inspiration images'
                        )}
                      </li>
                    </Link>
                    <Link
                      onClick={() => Save({ activeTab: 'ROOMS' }, 'app')}
                      to={`/add-project/${id}`}
                    >
                      <li>
                        {switchStatus(
                          rooms_completed,
                          'Step 3: Measure & photograph your space(s)'
                        )}
                      </li>
                    </Link>
                    <Link
                      onClick={() => Save({ activeTab: 'GOODS' }, 'app')}
                      to={`/add-project/${id}`}
                    >
                      <li>
                        {switchStatus(
                          goods_completed,
                          'Step 4: Measure & Photograph items you plan to keep'
                        )}
                      </li>
                    </Link>
                  </ul>
                </div>
                <Row className="avatars">
                  <Link
                    style={{ display: 'flex', color: 'red' }}
                    onClick={() => Save({ activeTab: 'MESSAGEBOARD' }, 'app')}
                    to={`/add-project/${id}`}
                  >
                    {messages_length && <>{messages_length} </>}{' '}
                    <MessageOutlined />
                  </Link>
                  <Avatar.Group
                    maxCount={4}
                    size="small"
                    maxStyle={{
                      backgroundColor: '#fde3cf',
                    }}
                  >
                    {users &&
                      users.length !== 0 &&
                      users.map((user: any) => {
                        let src =
                          URL + user.avatar;

                        return (
                          <Avatar
                            key={src}
                            src={src}
                          >
                            {user.avatar}
                          </Avatar>
                        );
                      })}
                  </Avatar.Group>
                </Row>
                <Row
                  justify="end"
                  className="detail-btn"
                >
                  <Button className="view-details">
                    <Link
                      onClick={() => editProject()}
                      to={`/add-project/${id}`}
                    >
                      View Details
                    </Link>
                  </Button>
                  {projectListType === 'activate' && role !== 'client' && (
                    <Button
                      onClick={() => showArchiveModal(id)}
                      className="archive"
                    >
                      Archive
                    </Button>
                  )}
                  {projectListType === 'archive' && role !== 'client' && (
                    <Button
                      onClick={() => showArchiveModal(id)}
                      className="activate"
                    >
                      Activate
                    </Button>
                  )}
                </Row>
              </Card>
            </div>
          </Col>
          {projectListType === 'activate' && (
            <ConfirmModal
              questionText={''}
              questionTopText={strings.doYouWantToArchiveTheProject}
              okText={strings.uppercaseCancel}
              cancelText={strings.yesArchiveProject}
              isModalVisible={archiveModalVisible}
              handleOk={archiveProject}
              handleCancel={() => setArchiveModalVisible(false)}
            />
          )}
          {projectListType === 'archive' && (
            <ConfirmModal
              questionText={''}
              questionTopText={'Do You Want To Activate This Project?'}
              okText={strings.uppercaseCancel}
              cancelText={'Yes,Activate'}
              isModalVisible={archiveModalVisible}
              handleOk={activateProject}
              handleCancel={() => setArchiveModalVisible(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default CardItemView;
