import { memo, useEffect } from 'react';
import './styles.css';
import { Button } from 'antd';
import { EyeSVG, TrashSVG } from 'assets/index';
import {
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useRedux } from 'src/hooks';

const FileItemView = (props: any) => {
  const {
    width,
    finallShowImagePress,
    removeFilePress,
    id,
    name,
    size = { size: '', type: '' },
    fileImage,
    DataURIToBlob,
    type,
    format,
    disabled,
    onEdit,
    file,
    degree,
    isEditable = false,
  } = props;
  const { Get } = useRedux();
  const { nameOfRotatedFile, activeTab, editable } = Get().app;
  const rotateImageData = {
    url: fileImage,
    type: type,
    name: nameOfRotatedFile,
    id: id,
    size: size,
    format: format,
    rotate_angle: file.rotate_angle,
  };
  const isVideo = (fileImage: any) =>
    fileImage?.toUpperCase().includes('AVI') ||
    fileImage?.toUpperCase().includes('FLV') ||
    fileImage?.toUpperCase().includes('WMV') ||
    fileImage?.toUpperCase().includes('MP4') ||
    fileImage?.toUpperCase().includes('MOV');
  const isPNG =
    fileImage?.toUpperCase().includes('PNG') ||
    fileImage?.toUpperCase().includes('TIFF') ||
    fileImage?.toUpperCase().includes('JPG') ||
    fileImage?.toUpperCase().includes('JPEG') ||
    fileImage?.toUpperCase().includes('GIF') ||
    fileImage?.toUpperCase().includes('EPS') ||
    fileImage?.toUpperCase().includes('HEIF') ||
    fileImage?.toUpperCase().includes('DNG') ||
    fileImage?.toUpperCase().includes('RAW');
  useEffect(() => {}, [file, degree]);

  return (
    <div
      style={{ width }}
      className="fileItem-container"
    >
      <div className="detail-container">
        {type === 'unUploaded' ? (
          !isVideo(format) && format !== 'pdf' && fileImage ? (
            <>
              {' '}
              <div>
                <Button className="file-picture">
                  <img
                    style={
                      file.rotate_angle
                        ? {
                            width: 37,
                            height: 37,
                            transform: `rotate(${file.rotate_angle}deg)`,
                          }
                        : {
                            width: 37,
                            height: 37,
                          }
                    }
                    src={URL.createObjectURL(DataURIToBlob(fileImage))}
                    alt=""
                  />
                </Button>
              </div>
            </>
          ) : (
            <Button className="file-picture">
              {isVideo(format) ? <VideoCameraOutlined /> : <FilePdfOutlined />}
            </Button>
          )
        ) : fileImage && !isVideo(fileImage) && format !== 'pdf' ? (
          <>
            {' '}
            <div>
              <Button className="file-picture">
                <img
                  style={{
                    width: 37,
                    height: 37,
                    transform: `rotate(${file.rotate_angle}deg)`,
                  }}
                  src={fileImage}
                  alt=""
                />
              </Button>
            </div>
          </>
        ) : (
          <Button className="file-picture">
            <a
              href={fileImage}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              {isVideo(fileImage) ? (
                <VideoCameraOutlined />
              ) : (
                <>
                  <FilePdfOutlined />
                </>
              )}
            </a>
          </Button>
        )}

        <span className="file-name-text">{name}</span>
        <span className="size-file-text">
          {size.size} {size.type}
        </span>
      </div>

      {type && type === 'unUploaded' ? (
        !isVideo(format) && format !== 'pdf' ? (
          <Button
            onClick={() => finallShowImagePress()}
            className="eye-container"
          >
            <img
              src={EyeSVG}
              alt=""
            />
          </Button>
        ) : null
      ) : isPNG && format !== 'pdf' ? (
        <Button
          onClick={() => finallShowImagePress()}
          className="eye-container"
        >
          <img
            src={EyeSVG}
            alt=""
          />
        </Button>
      ) : fileImage && name ? (
        <Button className="eye-container">
          <a
            target={'_blank'}
            rel="noopener noreferrer"
            href={fileImage}
            download={name}
          >
            <DownloadOutlined />
          </a>
        </Button>
      ) : null}
      {isEditable &&
        activeTab !== 'PROJECTDATA' &&
        activeTab !== 'PAYMENT' &&
        format !== 'mp4' &&
        format !== 'pdf' && (
          <Button
            className="eye-container"
            onClick={() => {
              onEdit(rotateImageData);
            }}
          >
            <EditOutlined />
          </Button>
        )}
      {editable && (
        <Button
          onClick={() => removeFilePress(id, file, type)}
          className="trash-container"
          disabled={disabled}
        >
          <img
            src={TrashSVG}
            alt=""
          />
        </Button>
      )}
    </div>
  );
};

export default memo(FileItemView);
