import { api } from 'api/api';
const END_POINTS = {
  presentation: '/projects/{project_pk}/presentation/',
  presentationById: '/projects/{project_pk}/presentation/{id}/',
};

export const PresentationService = {
  presentationList: async (project_id: string) => {
    return await api
      .get(
        END_POINTS.presentation.replace('{project_pk}', project_id),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  },
  presentationDetail: async (project_id: string, presentationId: string) => {
    return await api
      .get(
        END_POINTS.presentationById
          .replace('{project_pk}', project_id)
          .replace('{id}', presentationId),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  },
  createPresentation: async (project_id: string, data: any) => {
    return await api
      .post(
        END_POINTS.presentation.replace('{project_pk}', project_id),
        data,
        null,
        true
      )
      .then((response) => {
        console.log('response createPresentation', response)
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  },
  deletePresentation: async (project_id: string, presentationId: string) => {
    return await api
      .delete(
        END_POINTS.presentationById
          .replace('{project_pk}', project_id)
          .replace('{id}', presentationId),

        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return null;
      });
  },
};
